/* Importing fonts and tailwind directives */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Light and dark mode base colors defined in :root for easy access */
:root {
  --bg-color-light: #f4f4f4; /* Light mode background */
  --text-color-light: #333; /* Light mode text color */
  --bg-color-dark: #1a202c; /* Dark mode background */
  --text-color-dark: #f1f5f9; /* Dark mode text color */
  --button-bg-light: #e2e8f0; /* Light mode button background */
  --button-hover-bg-light: #cbd5e1; /* Light mode button hover background */
  --button-bg-dark: #2d3748; /* Dark mode button background */
  --button-hover-bg-dark: #4a5568; /* Dark mode button hover background */
}

/* Dark mode using a class on html */
html.dark {
  --background: var(--bg-color-dark);
  --text: var(--text-color-dark);
  --button: var(--button-bg-dark);
  --buttonHover: var(--button-hover-bg-dark);
}

/* Light mode applied by default, dark mode will override */
html {
  --background: var(--bg-color-light);
  --text: var(--text-color-light);
  --button: var(--button-bg-light);
  --buttonHover: var(--button-hover-bg-light);
  scroll-behavior: smooth;
}

body {
  background-color: var(--background); /* Applied globally */
  color: var(--text);
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.6;
}

/* Apply Montserrat font to all headings and adjust sizes */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600; /* Bold headings for impact */
  letter-spacing: -0.05em; /* Slight negative spacing for tight heading look */
  margin-bottom: 0.75rem; /* Space after headings */
}

.StrongLightMode {
  color: #000;
}

/* Header and other typography settings maintained */
h1 {
  font-size: 1.875rem; /* 30px on mobile */
  line-height: 1.2;
  margin-bottom: 0.75rem;
}
h2 {
  font-size: 1.5rem; /* 24px on mobile */
  line-height: 1.3;
  margin-bottom: 0.75rem;
}
h3 {
  font-size: 1.25rem; /* 20px on mobile */
  line-height: 1.4;
  margin-bottom: 0.75rem;
}
h4 {
  font-size: 1.125rem; /* 18px on mobile */
  line-height: 1.5;
  margin-bottom: 0.75rem;
}
h5 {
  font-size: 1rem; /* 16px, same as body */
  line-height: 1.6;
  margin-bottom: 0.75rem;
}
h6 {
  font-size: 0.875rem; /* 14px on mobile */
  line-height: 1.6;
  margin-bottom: 0.75rem;
}

.flex {
  display: flex;
}

.min-h-screen {
  min-height: 100vh;
}

.sticky {
  position: sticky;
  top: 0;
}

.overflow-y-auto {
  overflow-y: auto;
}

.p-4 {
  padding: 1rem;
}

ul {
  font-family: "Montserrat", sans-serif;
}

/* Button styles */
button {
  background-color: var(--button);
  color: var(--text);
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--buttonHover);
}

.asideTop {
  padding-top: 50px !important;
  padding-bottom: 50px;
}

.hide {
  padding-bottom: 20px;
}

code,
pre {
  font-family: "Fira Code", "Courier New", monospace;
}

/* Responsive settings for larger screens */
@media (min-width: 768px) {
  .textLogo {
    font-size: 2.25rem; /* 36px on desktop */
    line-height: 1.2;
  }
  h1 {
    font-size: 2.25rem; /* 36px on desktop */
  }
  h2 {
    font-size: 1.75rem; /* 28px on desktop */
  }
  h3 {
    font-size: 1.5rem; /* 24px on desktop */
  }
  h4 {
    font-size: 1.25rem; /* 20px on desktop */
  }
  h5 {
    font-size: 1.125rem; /* 18px on desktop */
  }
  h6 {
    font-size: 1rem; /* 16px on desktop */
  }
  body {
    font-size: 1.125rem; /* Slightly larger text on desktop */
  }

  .asideTop {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .mainTop {
    padding-top: 100px !important;
  }

  .hide {
    display: none;
  }
}

/* Webkit Browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #4f46e5; /* Indigo */
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6366f1; /* Lighter indigo on hover */
}

/* Scrollbar arrows (buttons) */
::-webkit-scrollbar-button {
  background-color: transparent;
  height: 16px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 0 L0 4 L8 4 Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 8 L0 4 L8 4 Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #4f46e5 transparent;
}
